<template>
    <div>
        <div class="d-flex justify-content-between mt-4 mb-2">
            <h3>Användare</h3>
            <b-button v-if="isShowingAvailableUsersToAdd"
                      variant="primary"
                      class="float-right"
                      @click="addUserToCustomer">
                Lägg till
            </b-button>
        </div>

        <template v-if="!isShowingAvailableUsersToAdd">
            <b-form-group id="form-group-select" label="Välj användare">
                <b-form-select v-model="selectedUser"
                               :options="userWithoutPermissions"
                               value-field="userId"
                               text-field="userFullName" />
            </b-form-group>

            <b-alert :show="validationByKey('Other').length > 0"
                     variant="danger">
                <ul class="text-danger mb-0">
                    <li v-for="message in validationByKey('Other')" :key="message">
                        {{ message }}
                    </li>
                </ul>
            </b-alert>

            <div class="d-flex justify-content-between mt-4">
                <b-button variant="warning"
                          @click="isShowingAvailableUsersToAdd = true"
                          :disabled="isSubmitting">
                    Avbryt
                </b-button>
                <b-button variant="primary"
                          @click="add(1)"
                          :disabled="isSubmitting">
                    Lägg till
                </b-button>
            </div>
        </template>

        <!--Add-->
        <b-table v-if="isShowingAvailableUsersToAdd"
                 :items="alreadySelectedUsers"
                 :fields="fields"
                 stacked="sm"
                 show-empty
                 small>
            <template #cell(writeAccess)="row">
                <b-form-checkbox @change="toggleWriteAccess($event, row.item.userId)"
                                 v-model="row.item.hasWriteAccess" />
            </template>
            <template #cell(userFullName)="row">
                <div class="overflow-hidden">
                    {{ row.item.userFullName }}
                </div>
            </template>
            <template #cell(actions)="row">
                <font-awesome-icon icon="trash-alt"
                                   role="button"
                                   class="d-none d-sm-block text-red"
                                   size="lg"
                                   :disabled="isSubmitting"
                                   @click="confirm('remove', row.item.userId)" />

                <b-button class="text-center d-block d-sm-none"
                          block
                          variant="danger"
                          :disabled="isSubmitting"
                          @click="confirm('remove', row.item.userId)">
                    Ta bort
                </b-button>
            </template>
            <template #empty="">
                <h4 class="text-center"></h4>
            </template>
        </b-table>
    </div>
</template>

<script>
    import { get, post, remove } from '@/helpers/api';

    export default {
        props: {
            customerId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                validationErrors: null,
                isSubmitting: false,
                selectedUser: null,
                isShowingAvailableUsersToAdd: true,
                userWithoutPermissions: [],
                alreadySelectedUsers: null,
                fields: [
                    {
                        key: 'writeAccess',
                        label: 'Skriv',
                        sortable: false,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'userFullName',
                        label: 'Namn',
                        sortable: false,
                        sortDirection: 'desc'
                    },
                    { key: 'actions', label: '', tdClass: 'table-list--actions--wrapper' }
                ]
            };
        },
        async mounted() {
            await this.getAllUsersByCustomerId();
        },
        methods: {
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            },
            toggleWriteAccess(shouldHaveWrite, selectedUserId) {
                this.selectedUser = selectedUserId;
                this.add(shouldHaveWrite ? 4 : 1);
            },
            async remove(userId) {
                // Remove users permissions relating to customer
                // Could not use "delete" in helpers/api.js, probably bcz it's a reserved keyword
                await remove(
                    'UserCustomerPermission',
                    `DeleteAll?customerId=${this.customerId}&userId=${userId}`
                )
                    .then((x) => {
                        this.getAllUsersByCustomerId();
                        this.validationErrors = x.data.errors;
                    })
                    .catch(x => {
                        this.isSubmitting = false;
                        this.validationErrors = x.response.data.errors
                    });
            },
            async addUserToCustomer() {
                this.isShowingAvailableUsersToAdd = false;
                await this.getAllUsersWithoutAnyCustomerPermissions();
            },
            async add(accessMask) {
                this.isSubmitting = true;
                // Add user to customer.
                await post(
                    'UserCustomerPermission',
                    `Add`, { customerId: this.customerId, userId: this.selectedUser, accessMask: accessMask}
                )
                    .then((x) => {
                        this.getAllUsersByCustomerId();
                        this.isShowingAvailableUsersToAdd = true;
                        this.selectedUser = null;
                        this.isSubmitting = false;
                        this.validationErrors = x.data.errors;
                    })
                    .catch(x => {
                        this.isSubmitting = false;
                        this.validationErrors = x.response.data.errors
                    });
            },
            async getAllUsersByCustomerId() {
                // Get all users by customer.
                await get('UserCustomerPermission', `GetAllUsersByCustomerId/${this.customerId}`)
                    .then((x) => {
                        this.alreadySelectedUsers = x.data;
                        this.validationErrors = x.data.errors;
                    })
                    .catch(x => {
                        this.isSubmitting = false;
                        this.validationErrors = x.response.data.errors
                    });
            },
            async getAllUsersWithoutAnyCustomerPermissions() {
                await get('UserCustomerPermission', `GetAllUsersWithoutAnyCustomerPermissions/${this.customerId}`)
                    .then((x) => {
                        this.userWithoutPermissions = x.data;
                        this.validationErrors = x.data.errors;
                    })
                    .catch(x => {
                        this.isSubmitting = false;
                        this.validationErrors = x.response.data.errors
                    });
            },
            confirm(action, data) {
                this.$bvModal
                    .msgBoxConfirm('Vill du ta bort användaren från kunden?', {
                        title: 'Är du säker?',
                        size: 'sm',
                        cancelVariant: 'primary',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'danger',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        if (confirmed) this[action](data);
                    })
                    .catch((err) => {
                        // An error occurred
                    });
            }
        }
    };
</script>
