<template>
    <div style="display: inline-block;">
        <font-awesome-icon class="d-none d-sm-inline ml-1"
                           :class="{'text-success' : selectedId === itemId, 'text-info' : selectedId !== itemId}"
                           :icon="selectedId === itemId ? 'toggle-on' : 'toggle-off'"
                           size="lg"
                           :disabled="isDisabled"
                           role="button"
                           :title="selectedId === itemId ? selectText || defaultSelectText : selectedText || defaultSelectedText"
                           @click="select(itemId)" />

        <b-button class="text-center d-block d-sm-none mt-2"
                  :variant="selectedId === itemId ? 'success' : 'info'"
                  block
                  @click="select(itemId)"
                  :disabled="isDisabled || selectedId === itemId">
            {{ selectedId === itemId ? selectText || defaultSelectText : selectedText || defaultSelectedText}}
        </b-button>
    </div>
</template>

<script>
    export default {
        name: 'TableToggleItem',
        props: {
            isDisabled: Boolean,
            selectedId: {
                type: [String, Number],
                required: true
            },
            itemId: {
                type: [String, Number],
                required: true
            },
            selectText: {
                type: String,
                required: false
            },
            selectedText: {
                type: String,
                required: false
            },
        },
        data() {
            return {
                defaultSelectText: 'Välj',
                defaultSelectedText: 'Vald'
            }
        },
        methods: {
            select(id) {
                if(id !== this.selectedId)
                    this.$emit('onToggled', id);
            }
        }
    };
</script>